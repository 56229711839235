// uncomment here
// @import './utilities/variables.scss';
// @import './utilities/mixins.scss';
// @import './utilities/reset.scss';
// @import './utilities/soleil-typekit.scss';
// @import './utilities/embed.scss';
// @import './component-styles/components.scss';
// @import './page-styles/pages.scss';

// html,
body {
    padding: 0;
    margin: 0;
    // font-family: $primary-font;
    // background: $white;
    // color: $midnight;
    // scroll-behavior: smooth;
}

// .overflow-hidden {
//     overflow: hidden;
// }

// #main-wrapper {
//     min-height: 100vh;
//     display: flex;
//     flex-direction: column;
//     overflow: hidden;

//     .content-wrapper {
//         max-width: 1440px;
//         margin: 0 auto;
//         position: relative;
//     }
// }
